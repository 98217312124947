<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <section class="auth auth--reset">
    <div class="auth__container">
      <div class="auth__logo">
        <FullLogoIcon />
      </div>
      <form
        class="auth__form"
        autocomplete="off"
        spellcheck="false"
        ref="form"
        @submit.prevent="restorePassword"
      >
        <h1 class="auth__title">Восстановить пароль</h1>
        <InputComponent
          title="Введите пароль"
          :type="!showPassword ? 'password' : 'text'"
          :error="$v.resetForm.password.value.$error && submitStatus === 'ERROR'"
          v-model.trim.lazy="$v.resetForm.password.value.$model"
          @submit="showPassword = !showPassword"
        >
          <template v-slot:action="">
            <EyeOffIcon v-if="!showPassword" />
            <EyeOnIcon v-else />
          </template>
        </InputComponent>
        <InputComponent
          title="Подтвердите пароль"
          :type="!showPasswordConfirmation ? 'password' : 'text'"
          :error="$v.resetForm.password_confirmation.value.$error && submitStatus === 'ERROR'"
          v-model.trim.lazy="$v.resetForm.password_confirmation.value.$model"
          @submit="showPasswordConfirmation = !showPasswordConfirmation"
        >
          <template v-slot:action="">
            <EyeOffIcon v-if="!showPasswordConfirmation" />
            <EyeOnIcon v-else />
          </template>
        </InputComponent>
        <button type="submit" class="btn" :class="{ 'btn--loading': submitStatus === 'PENDING' }">
          <template v-if="submitStatus !== 'PENDING'">Изменить пароль</template>
        </button>
      </form>
      <div class="auth__switch">
        <span>Вспомнили пароль?</span>
        <router-link :to="{ name: 'Login' }">Войти</router-link>
      </div>
    </div>
  </section>
</template>

<script>
import FullLogoIcon from "@/components/svg/FullLogoIcon";
import InputComponent from "@/components/inputs/InputComponent";
import { minLength, required } from "vuelidate/lib/validators";
import EyeOnIcon from "@/components/svg/EyeOnIcon";
import EyeOffIcon from "@/components/svg/EyeOffIcon";

export default {
  name: "ResetPasswordPage",
  data() {
    return {
      showPassword: false,
      showPasswordConfirmation: false,
      resetForm: {
        password: {
          message: null,
          value: null,
        },
        password_confirmation: {
          message: null,
          value: null,
        },
      },
      submitStatus: null,
    };
  },
  validations: {
    resetForm: {
      password: {
        value: {
          required,
          minLength: minLength(8),
        },
      },
      password_confirmation: {
        value: {
          required,
          minLength: minLength(8),
        },
      },
    },
  },
  methods: {
    async restorePassword() {
      if (this.$route.params.token) {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.submitStatus = "ERROR";
        } else {
          this.submitStatus = "PENDING";
          this.$store
            .dispatch("RESET_PASSWORD", {
              context: this,
              variables: {
                token: this.$route.params.token,
                password: this.resetForm.password.value,
                password_confirmation: this.resetForm.password_confirmation.value,
              },
            })
            .then(() => {
              this.submitStatus = "OK";
              this.$notify({
                title: "Успешно",
                text: "Новый пароль установлен",
                type: "success",
                duration: 5000,
              });
              this.$router.push({ name: "Login" });
            })
            .catch(() => {
              this.submitStatus = "INVALID";
            });
        }
      }
    },
  },
  components: { EyeOffIcon, EyeOnIcon, InputComponent, FullLogoIcon },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/auth"
</style>
